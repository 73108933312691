html {
	background: linear-gradient(#222, #000);
	line-height: 1.8;
	font-weight: 400;
	color: #ddd;
	font-size: 16px;
	font-family: Inter, 'Arial', Helvetica, sans-serif;
	text-align: center;
	min-height: 100%; }

.wraps {
	max-width: 600px;
	margin: 2rem auto;
	margin-bottom: 0;
	padding: 2rem;

	.logo-wrap {
		margin-bottom: 2rem;
		img {
			width: 256px; } } }

.textpage {
	text-align: left; }

.blurb {}

h2 {
	padding-bottom: 4px;
	text-transform: uppercase; }

footer {
	margin-top: 4rem;
	padding-top: 0;
	font-size: 70%;
	color: #aaa; }

a {
	color: #fff; }
